html {
  background: rgb(193,178,153);
  background: linear-gradient(297deg, rgba(193,178,153,1) 0%, rgba(255,255,255,1) 100%);
}

.App {
  width: 100%;
}


