.social-container {
    padding: 25px 50px;
  }
  
  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }
  
  a.social:hover {
    transform: translateY(-2px);
  }
  
  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: white;
  }
  
  a.twitter {
    color: white;
  }
  
  a.instagram {
    color: white;
  }