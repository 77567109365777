@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@supports not (backdrop-filter: blur(15px)) {
    .nav {
        position: sticky;
        width: 100vw;
        top: 0;
        box-shadow: 0px 2px 8px 4px rgba(0,0,0,0.10);
        background: inherit;
        background-color: rgba(255, 255, 255, 0.9);
        -webkit-backdrop-filter: blur(15px);
        z-index: 9999;
    }
}

@supports (backdrop-filter: blur(15px)) {
    .nav {
        position: sticky;
        width: 100vw;
        top: 0;
        box-shadow: 0px 2px 8px 4px rgba(0,0,0,0.10);
        background: inherit;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        z-index: 9999;
    }
}

:root {
    --scale: .01;
    --space: 8vmin;
    --contact-space: 2vmin;
}

@media (prefers-reduced-motion) {
    :root {
        --scale: 0;
    }
}

@media (width < 700px){
    .image-container {
        position: sticky;
        overflow-x: hidden;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: -2;
    }
}

@media ( width > 700px) {
    .image-container {
        position: sticky;
        overflow-x: hidden;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: -2;
    }
}

* {
    box-sizing: border-box;
}

body {
    color: hsl(0, 0%, 95%);
    background: rgb(193,178,153);
    background: linear-gradient(0deg, rgba(193,178,153,1) 0%, rgba(255,255,255,1) 100%);
    font-family: var(--font-primary);
    line-height: 1.6;
    overflow-x: hidden;
}

p, span {
    white-space: pre-line;
}

.footer {
    display: grid;
    place-items: center;
    background-color: #474239;
    height: 140px;
}

.section {
    transform-origin: center top;
    transform: scaleY(calc(1 - var(--scale)));
}

.content {
    position: relative;
    margin: -100vh auto 0;
    padding: var(--space);
    max-width: 95%;
    width: calc(100% - var(--space));
}

.content-contact {
    position: relative;
    margin: -100vh auto 0;
    padding: var(--space) var(--contact-space);
    max-width: 95%;
    width: calc(100% - var(--contact-space));
}

.content > * + * {
    margin-top: 1rem;
}

.image-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
}

.top-800 {
    top: 700px;
}

.h-108 {
    height: 26rem;
}

/* checkbox at bottom of form */

.rgpd-check {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 2rem;
}

.rgpd-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: -8px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #F5E9D6;
    border: 1px solid #F5E9D6;
    border-radius: 2px;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.rgpd-check:hover input ~ .checkmark {
    background-color: #5F5647;
    border: none;
}

.rgpd-check input:checked ~ .checkmark {
    background-color: #5F5647;
    border: none;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.rgpd-check input:checked ~ .checkmark:after {
    display: block;
}

.rgpd-check .checkmark:after {
    left: 5.5px;
    top: 1px;
    width: 9px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input::placeholder, textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: .85rem;
    color: #A69984;
    opacity: 1; /* Firefox */
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: .85rem;
    color: #A69984;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder { /* Microsoft Edge */
    font-size: .85rem;
    color: #A69984;
}